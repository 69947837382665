<template>
  <section class="noveltiesView" @keypress.esc="cancelData()">
    <div class="noveltiesView__wrapper">
      <div class="noveltiesView__container" v-if="$userData.role === 'monitor'">
        <div>
          <div class="noveltiesView__containerTitle">
            <h1>{{ title }}</h1>
          </div>
          <div class="noveltiesView__containerItems gcard" v-for="(info, ind) in novelietesData" :key="ind">
            <div>
              <p class="noveltiesView__containerItems-title">{{ getNameDay(ind) }}</p>
              <span class="noveltiesView__containerItems-moment"></span>
            </div>
            <div class="noveltiesView__containerItems-content" v-if="!isLoading">
              <div class="noveltiesView__containerItems-item" v-for="(novelity, idx) in info" :key="idx">
                <cardNovelties :openModal="openModal" :day="ind" :novelietesData="novelity"></cardNovelties>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="noveltiesView__view user" v-if="$userData.role === 'superadmin' || $userData.role === 'coordinator'">
        <router-view></router-view>
      </div>

      <div id="modalEditNovelties">
        <ModalVue class="modalEditNovelties " @onSubmit="sendData" @onCancel="cancelData" v-if="showModal">
          <template v-slot:body>
            <section class="noveltiesView__modalEdit ">
              <div class="noveltiesView__modalEdit-title">
                <h1>
                  {{ title }}
                </h1>
                <h6 @click="showModal = false"><iconic class="closeModal" name="close"></iconic></h6>
              </div>
              <section>
                <InfoNovelties :user="dataModal" :day="day"></InfoNovelties>
              </section>
            </section>
          </template>
          <template v-slot:footer><div /></template>
        </ModalVue>
      </div>
    </div>
  </section>
</template>
<script>
  import cardNovelties from "./UserCardnovelties.vue";
  import InfoNovelties from "./UserInfoNovelties.vue";
  import ModalVue from "../modals/Modal.vue";
  import mixinCrudNovelties from "./mixinCrudNovelties";
  export default {
    name: "NoveltiesView",
    mixins: [mixinCrudNovelties],
    components: {
      cardNovelties,
      ModalVue,
      InfoNovelties,
    },
    data() {
      return {
        title: "Novedades",
        showModal: false,
        dataModal: [],
        isLoading: false,
        novelietesData: [],
        day: "",
      };
    },
    methods: {
      sendData() {
        this.showModal = false;
      },
      cancelData() {
        this.showModal = false;
      },
      getNameDay(day) {
        return "MODELOS ASIGNADAS EL : " + this.$global.convertDateToString(new Date(day));
      },
      openModal(data, day) {
        this.day = day;
        this.dataModal = data;
        this.showModal = true;
      },
    },
    async beforeMount() {
      this.isLoading = true;
      const data = await this.getModelsAsignament();
      this.novelietesData = data.models;
      this.isLoading = false;
    },
  };
</script>

<style lang="scss">
  .noveltiesView {
    width: 100%;
    .closeModal {
      cursor: pointer;
    }
    @include screen_pc {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    &__wrapper {
      width: 100%;
      @include screen(1400px) {
        width: 1440px;
        padding-top: 50px;
      }
    }
    &__modalEdit {
      padding: 3px 20px;
      width: 100%;
      &-title {
        display: flex;
        justify-content: space-between;
      }
    }
    &__containerItems {
      margin-top: 15px;
      &-content {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(330px, 1fr));
        gap: 15px;
      }
      &-title {
        font-size: 18px;
        font-weight: bold;
        font-family: BebasKai;
      }
    }
    @media screen and (min-width: 768px) {
      margin: 0 30px;
    }
  }
</style>
