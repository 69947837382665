<template>
  <section class="cardNovelties">
    <div class="cardNovelties__container">
      <div :class="['cardNovelties__wrapper', dinamicClass()]">
        <div class="cardNovelties__content">
          <div class="cardNovelties__avatar">
            <avatar class="cardNovelties__avatar" :user="novelietesData.modelId"></avatar>
          </div>
          <div class="cardNovelties__text">
            <p v-if="novelietesData.modelId.user">{{ novelietesData.modelId.user || "" }}</p>
            <p>{{ novelietesData.modelId.city + " - " + novelietesData.modelId.office }}</p>
          </div>
        </div>
        <div class="cardNovelties__buttonC" v-if="validButtonActiveToAdd(day) && !sizeLentgth()">
          <button class="cardNovelties__button" @click="sendDataTomodal(novelietesData)">
            <iconic name="plusBold"></iconic>
          </button>
        </div>
        <div class="cardNovelties__buttonC" v-else-if="sizeLentgth()">
          <button class="cardNovelties__button green" @click="sendDataTomodal(novelietesData)">
            <iconic name="aprove"></iconic>
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import mixinCrudNovelties from "./mixinCrudNovelties";
export default {
  name: "cardNovelties",
  props: ["novelietesData", "openModal", "day"],
  mixins: [mixinCrudNovelties],
  data() {
    return {
      title: "",
    };
  },
  methods: {
    sendDataTomodal(data) {
      this.openModal(data, this.day);
    },

    sizeLentgth() {
      const data = this.novelietesData?.novelties.filter((item) => new Date(item.dateCreate).getDay() === new Date(this.day).getDay());
      return data?.length;
    },

    dinamicClass() {
      let data;
      this.novelietesData?.novelties.forEach((item) => {
        if (new Date(item?.dateCreate).getDay() === new Date(this.day).getDay() && item?.whoCreate === this.$userData._id) data = "succes";
      });
      return data || "notEvents";
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.cardNovelties {
  &__content,
  &__wrapper {
    display: flex;
  }
  &__text {
    margin-left: 15px;

    font-weight: 600;
  }
  &__wrapper {
    width: 100%;
    height: 52px;
    padding: 5px;
    border-radius: 4px;
    justify-content: space-between;
  }
  &__buttonC {
    padding-top: 5px;
  }
  .succes {
    background: rgba(15, 169, 12, 0.05);
    border: 1px solid #0fa90c;
    color: #0fa90c;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
  }
  .notEvents {
    background: rgba(189, 9, 9, 0.05);
    border: 1px solid #bd0909;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.16);
    color: #bd0909;
  }
  &__avatar {
    width: 43px;
    height: 43px;
  }
  &__button {
    color: white;
    width: 30px;
    border-radius: 50%;
    background-color: $primary-color;
    border: solid 1px $primary-color;
    .iconic {
      margin-top: 5px;
      font-weight: 500;
    }
    &:hover {
      background-color: #f52121;
      border: #f52121 solid 1px;
    }
  }
  .green {
    background-color: #0fa90c;
    border: #0fa90c solid 1px;
  }
}
</style>
